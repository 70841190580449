<template>
  <div class="aml-container">
    <Header></Header>

    <div class="aml-content">
      <div class="top-image">
        <div class="title">AML Policy</div>
      </div>
      <div class="aml-list">
        <div class="aml-list-title">Introduction</div>
        <div class="aml-items">
          <div class="description">
            Money laundering is the attempt to conceal or disguise the nature,
            location, source, ownership or control of illegally obtained money.
            Money laundering is most commonly associated with tax avoidance.
            However, other individuals may attempt to launder money in order to
            conceal their identity or finance their operations. ‘Suspicious
            activity’ is a very difficult concept to define because it can vary
            from one transaction to another based upon all the circumstances
            surrounding the transaction or group of transactions. For example,
            transactions by one customer may be normal based on our knowledge of
            that customer and their pattern of activity, while similar
            transactions by another customer may be suspicious. Many factors are
            involved in determining whether transactions are suspicious
            including the amount, the nature of the transaction and frequency of
            deposits/withdrawals from the system.
          </div>
          <div class="description">
            <span>Giftcard8</span> is committed to fighting money laundering and
            complying fully with anti-money laundering laws in the Nigeria. We
            understand that we have responsibilities to help fight the global
            battle against money laundering and our commitment will supersede
            all other privacy obligations contained in our policies.
            Accordingly, <span>GiftCard8</span> will take all reasonable and
            appropriate steps to prevent persons engaged in money laundering,
            fraud, or other financial crimes from utilizing our products and
            services.
          </div>
          <div class="description">
            Our AML policies, procedures and internal controls are designed to
            ensure compliance with all applicable BSA regulations and FINRA
            rules and will be reviewed and updated on a regular basis to ensure
            appropriate policies, procedures and internal controls are in place
            to account for both changes in regulations and changes in our
            business. Key components of our AML and CTF framework include the
            following:
          </div>
        </div>
        <div class="aml-items">
          <div class="title">1. Compliance officer</div>
          <div class="description">
            <div>
              (1)Coordinating and monitoring day-to-day compliance with the
              relevant legislation, regulations, rules and industry guidance and
              applicable money laundering laws and regulations
            </div>
            <div>
              (2)Monitoring transactions to detect unusual suspicious activities
            </div>
            <div>
              (3)Prompt preparation and delivery of all relevant returns to the
              regulatory bodies in line with SEC and FIU Rules and Regulations
            </div>
            <div>(4)Communicating AML/CFT issues to all stakeholders</div>
          </div>
        </div>
        <div class="aml-items">
          <div class="title">2. Risk committee</div>
          <div class="description">
            The appointment of an independent risk committee which reports to
            our board of directors regularly on all risk and compliance matters
            using a risk-based approach to the assessment and management of
            money laundering and terrorist financing risks and Comply with the
            requirements of the Money Laundering (Prohibition) Act, 2011 (as
            amended), Terrorism (Prevention) Act, 2011 (as amended) and
            Terrorism Prevention (Freezing of International Terrorists Funds and
            other Related Measures) Regulations 2013, including related laws and
            Regulations.
          </div>
        </div>
        <div class="aml-items">
          <div class="title">3. Internal control</div>
          <div class="description">
            Formulate and implement internal controls and other procedures that
            will deter criminals from using its facilities for money laundering
            and terrorist financing and to ensure that its obligations under
            subsisting laws and Regulations are met.
          </div>
        </div>
        <div class="aml-items">
          <div class="title">4. Know your customer</div>
          <div class="description">
            Establishing and maintaining a risk-based approach to Customer Due
            Diligence (CDD), including customer identification, verification and
            KYC procedures. To ensure we meet these standards, our customers are
            required to provide certain personal details when opening a
            <span>Giftcard8</span> account and also generate tokens, OTP for any
            withdrawal request. The nature, and extent, of what is required is
            guided by the customer’s deposit and withdrawal limits, account to
            be used and in some cases, the customer’s country of residence. In
            certain circumstances,<span>Giftcard8</span> may perform enhanced
            due diligence procedures for customers presenting a higher risk,
            such as those transacting large volumes etc. Maintaining appropriate
            KYC records for the minimum prescribed periods;
          </div>
          <div class="description">
            <span>Giftcard8</span> shall ensure timely and accurate rendition of
            all AML/CFT returns as specified in the SEC AML/CFT Rules and
            Regulations as well as other relevant
            Regulations/Act/Guidelines/Circulars that may be issued from time to
            time by various government agencies.
          </div>
          <div class="description">
            We shall exercise due diligence in identifying and reporting a
            suspicious transaction. Suspicious transactions shall include:
            <div>
              (1) Transactions which are structured to avoid reporting and
              record keeping requirements.
            </div>
            <div>
              (2) Altered or false identification or inconsistent information or
              any transaction involving criminal activity in Giftcard8’s view.
            </div>
            <div>
              (3) Entity that belongs to a person or organization considered as
              terrorist.
            </div>
            <div>
              (4) <span>Giftcard8</span> shall also provide training on the
              framework and raising awareness among all relevant employees and
              every Settlement proceeds are only wired to verified bank accounts
              registered by us.
            </div>
          </div>
        </div>
        <div style="text-align: center;font-weight: bold;">This page was last update in June 2022</div>
      </div>
      <el-backtop :bottom="100">
        <div
          style="
            height: 100%;
            width: 100%;
            background-color: #f2f5f6;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
            text-align: center;
            line-height: 40px;
            color: #1989fa;
          "
        >
          UP
        </div>
      </el-backtop>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import Header from "../../components/header.vue";
import Footer from "../../components/footer.vue";

export default defineComponent({
  name: "Aml",
  components: { Header, Footer },
  setup() {
    onMounted(() => {
      document.documentElement.scrollTop = 0;
    });

    return {};
  },
});
</script>

<style lang="less">
.aml-container {
  position: relative;
  background-color: rgba(247, 247, 247, 1);
  .aml-content {
    .top-image {
      width: 100%;
      height: 600px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      background-image: url("../../assets/images/card_bg.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      font-size: 20px;
      .title {
        margin-bottom: 36px;
        font-size: 60px;
      }
    }
    .aml-list {
      position: relative;
      overflow: hidden;
      margin: 20px 200px;
      padding: 80px;
      background-color: rgba(255, 255, 255, 1);
      border-radius: 10px;
      .aml-list-title {
        color: rgba(0, 11, 51, 1);
        font-size: 30px;
        font-weight: bold;
      }
      .aml-items {
        margin-top: 40px;
        margin-bottom: 20px;
        text-align: left;
        .title {
          margin-bottom: 20px;
          color: rgba(51, 51, 51, 1);
          font-size: 26px;
        }
        .description {
          line-height: 25px;
          margin-bottom: 30px;
          span {
            color: #0f4392;
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>
